<template>
    <div class="containerFooter font16 flex alignItemsCenter justifyContentCenter">
        <span>版权所有 2024版权所有</span>
    </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style lang="scss" scoped>
    .containerFooter {
        height: 73px;
        color: #878ea6;
        background-color: #1d2236;
    }
</style>
