<template>
    <el-tooltip :content="contentText" :placement="placement">
        <slot name="content"></slot>
        <template slot="content">
            <slot name="contentText"></slot>
        </template>
    </el-tooltip>
</template>

<script>
    export default {
        name: "index",
        props: {
            contentText: {
                type: String,
                default: () => '还未注明说明（默认）'
            },
            placement: {
                type: String,
                default: () => 'top-start'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
