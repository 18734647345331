import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

import HeaderComponent from './components/header'
import FooterComponent from './components/footer'
import UseInputComponent from './components/useInput'
import UseTooltip from './components/useTooltip'
import UseDialog from './components/useDialog'

// 全局注册组件
Vue.component('HeaderComponent', HeaderComponent)
Vue.component('FooterComponent', FooterComponent)
Vue.component('UseInputComponent', UseInputComponent)
Vue.component('UseTooltip', UseTooltip)
Vue.component('UseDialog', UseDialog)


import mixins from './mixins'

Vue.mixin(mixins);  // 注册全局 Mixin

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
