import store from "@/store";
import {getUserInfoApi, getUserGameInfo, getGameUrlMainApi, getGameUrlApi} from "@/api"

export default {
    methods: {
        useInterceptedOnMounted(callback) {
            this.$nextTick(() => {
                let recursionDesired = async () => {
                    let _next = window?.configGlobal
                    if (_next) {
                        callback()
                    } else {
                        setTimeout(() => {
                            recursionDesired()
                        }, 100)
                    }
                }
                recursionDesired()
            })
        },
        setStoreState(payload) {
            store.commit('setStateVal', payload)
        },
        //验证是否登录
        isLogin(modal) {
            let _bool = JSON.stringify(store.state.userInfo) !== '{}' ? true : false
            if (modal && !_bool) {
                this.$message({type: 'warning', message: '请先登录'})
                this.setStoreState({prop: 'showLogin', value: 'login'})
            }
            return _bool
        },
        //更新获取个人信息
        async updateInfo(agent) {   //agent => dom 的ref
            let _oldName = ''
            if (agent) {
                if (this.$refs[agent].classList.value.indexOf('rotateSpin') >= 0) return
                _oldName = this.$refs[agent].className
                this.$refs[agent].className += ' rotateSpin'
            }
            let _res = null
            if (store.state.token) {	//验证TOken 登录状态才更新信息
                await getUserInfoApi().then(res => {
                    if (!res.data) return
                    // res.data.balance = 20000
                    // res.data.hasPinCode = false
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                    this.setStoreState({
                        prop: 'userInfo',
                        value: res.data
                    })
                    _res = res.data
                })
                //跟新我的游戏盈亏 注单
                await getUserGameInfo().then(res => {
                    if (res.data) {
                        this.setStoreState({
                            prop: 'bagInfo',
                            value: res.data
                        })
                    }
                })

            }
            setTimeout(() => {
                if (agent) {
                    this.$refs[agent].className = _oldName
                }
            }, 1000)
            return _res
        },
        checkUrlSpeed(url) {    //筛选最快链接
            return new Promise((resolve, reject) => {
                let startTime = Date.now();
                fetch(url, {
                    method: 'GET',
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Failed to fetch: ${url} - Status code: ${response.status}`);
                        }
                        return response.json(); // Assuming the response is JSON
                    })
                    .then(data => {
                        if (data?.code === 0) { // Assuming 'code' is part of the response data
                            let endTime = Date.now();
                            let duration = endTime - startTime;
                            resolve({
                                url: url,
                                time: duration
                            });
                        } else {
                            reject(new Error('Response code is not 0: ' + url));
                        }
                    })
                    .catch(error => {
                        reject(new Error('Fetch error: ' + url + ' - ' + error.message));
                    });
            });
        },
        //登录游戏
        async loginGame(item, callBack) {
            // console.log(item);
            if (!this.isLogin(true)) return

            //status === 0
            if (item.status === 0) {
                this.$message({type: 'warning', message: '维护中'})
                return
            }
            //缓存当前游戏配置信息

            //如果PlatId为1  则调用 “获取官方游戏登录链接”接口
            let _res = null
            // if (item.platId === 1) {
            //     _res = await getGameUrlMainApi({
            //         id: item.id,
            //         ingress: 'pc' //移动端传h5 PC端传pc
            //     })
            //
            //     if (!_res.data) return
            //
            //     let _urls = _res.data.urls || []
            //     let params = _res.data.params
            //     let lastUrl = null
            //
            //     // 选择最快的
            //     try {
            //         let results = []
            //         _urls.forEach(url => {
            //             results.push(this.checkUrlSpeed(url +
            //                 `/ping.json?t=${Math.ceil(Math.random() * 1000000)}`))
            //         })
            //         Promise.any(results).then(res => {
            //             lastUrl = res.url.substring(0, res.url.indexOf('/ping')) + params
            // sessionStorage.setItem('webViewUrl',_res.data.gameUrl)
            //             this.setStoreState({prop: 'webViewUrl', value: lastUrl})
            //             callBack && callBack()
            //         }).catch(err => {
            //             this.$message({type: 'error', message: '网络连接错误！'})
            //         })
            //     } catch (error) {
            //         this.$message({type: 'error', message: '网络连接错误！'})
            //     }
            //     return
            // }
            //如果PLATid不为1的时候， 调用“获取第三方游戏登录链接接口”
            // if (item.platId !== 1) {
            _res = await getGameUrlApi({
                id: item.id,
                ingress: item.ingress.split('|')[0] //移动端传ingress[1] PC端传ingress[0]
            })

            if (!_res.data) return

            sessionStorage.setItem('webViewUrl', _res.data.gameUrl)
            sessionStorage.setItem('webViewConfig', JSON.stringify(item))
            this.setStoreState({prop: 'webViewConfig', value: item})
            this.setStoreState({prop: 'webViewUrl', value: _res.data.gameUrl})
            callBack && callBack()
            // return
            // }
        },
        disabledDate(time, dayNum) { //日期选择 限制多少天以内 time-> 当前时间 dayNum -> 限制天数
            const today = new Date();
            const thirtyDaysLater = new Date();
            thirtyDaysLater.setDate(today.getDate() - dayNum);

            return time.getTime() >= today.getTime() || time.getTime() < thirtyDaysLater.getTime();
        },
        exitFn() {   //退出登录
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('userInfo')

            // location.reload()
            //回到首页
            location.href = '/'
        }
    }
}
