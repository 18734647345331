import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {   //游戏列表
        path: '/gameList',
        name: 'gameList',
        component: () => import('../views/gameList')
    },
    {   //游戏
        path: '/webView/:type',
        name: 'webView',
        component: () => import('../views/webView')
    },
    {   //用户中心
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('../views/userCenter'),
        children: [
            {   //我的钱包
                path: 'myWallet',
                name: 'myWallet',
                component: () => import('../views/myWallet'),
                meta: {
                    title: '我的钱包'
                }
            },
            {   //资金明细
                path: 'financialDetails',
                name: 'financialDetails',
                component: () => import('../views/financialDetails'),
                meta: {
                    title: '资金明细'
                }
            },
            {   //数据统计
                path: 'dataStatistics',
                name: 'dataStatistics',
                component: () => import('../views/dataStatistics'),
                meta: {
                    title: '数据统计'
                }
            },
            {   //个人信息
                path: 'personalInformation',
                name: 'personalInformation',
                component: () => import('../views/personalInformation'),
                meta: {
                    title: '个人信息'
                }
            },
            {   //消息中心
                path: 'messageCenter',
                name: 'messageCenter',
                component: () => import('../views/messageCenter'),
                meta: {
                    title: '消息中心'
                }
            },
            {   //我的返水
                path: 'myRakeback',
                name: 'myRakeback',
                component: () => import('../views/myRakeback'),
                meta: {
                    title: '我的返水'
                }
            },
            {   //下载app
                path: 'appDown',
                name: 'appDown',
                component: () => import('../views/appDown'),
                meta: {
                    title: '扫描下载App'
                }
            },
            {   //安全中心
                path: 'securityCenter',
                name: 'securityCenter',
                component: () => import('../views/securityCenter'),
                meta: {
                    title: '安全中心'
                }
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
