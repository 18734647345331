<template>
    <!--    <el-dialog :visible="true" :show-close="false" :modal-append-to-body="false">-->
    <div class="containerUseDialog fixed w100 h100 flex alignItemsCenter justifyContentCenter">
        <div :style="{width:width + 'px'}" class="dialogContent flex column">
            <div class="title w100 relative textAlignCenter">
                <span class="font20" style="color: #39270c;">{{title}}</span>
                <img @click="closeCallBack" class="absolute hover pointer"
                     style="width: 14px;height: 14px;right: 25px;top: 25px;"
                     src="/images/logOn/close.png" alt="关闭">
            </div>
            <div class="flex1">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
    <!--    </el-dialog>-->
</template>

<script>
    export default {
        name: "index",
        props: {
            title: {
                type: String,
                default: () => '未指定标题（默认）'
            },
            closeCallBack: {
                type: Function,
                default: () => false
            },
            width: {
                type: [String, Number]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .containerUseDialog {
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 999;

        .dialogContent {
            background-color: #ebecf2;
            border-radius: 16px;

            .title {
                height: 75px;
                line-height: 75px;
            }
        }
    }

    ::v-deep {

        .el-dialog {
            background: unset;
            box-shadow: unset;
        }

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            padding: 0;
        }
    }
</style>
