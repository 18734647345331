<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    import {gameConfigTimeOutApi, getUserInfoTimeOutApi, sendPositionTimeOutApi} from '@/api'

    export default {
        created() {
            this.useInterceptedOnMounted(this.init)
        },
        methods: {
            init() {
                //每30/S执行定时任务
                setInterval(async () => {
                    if (this.isLogin(false)) {
                        await Promise.all([gameConfigTimeOutApi(), getUserInfoTimeOutApi()]).then(res => {
                            const [res1, res2] = res
                            {
                                localStorage.setItem('platformInfo', JSON.stringify(res1.data))
                                this.setStoreState({
                                    prop: 'gameConfig',
                                    value: res1.data
                                })
                            }
                            {
                                localStorage.setItem('userInfo', JSON.stringify(res2.data))
                                this.setStoreState({
                                    prop: 'userInfo',
                                    value: res2.data
                                })
                            }
                        })
                        //当进入的时候 每隔30秒， 发送轮训一次接口，
                        //如果没有进入游戏platid为 1，  plattype 为 空
                        let _currentGameConfig = {platId: 1, platType: ''}
                        if (window.location.href.indexOf('webView') >= 0) {
                            _currentGameConfig = JSON.parse(sessionStorage.getItem('webViewConfig') || "{ platId: 1, platType: '' }")
                        }
                        await sendPositionTimeOutApi({
                            platId: _currentGameConfig.platId,
                            platType: _currentGameConfig.platType
                        })
                    }
                }, 30 * 1000)
            }
        }
    }
</script>

<style lang="scss">
    @import '@/styles/common.scss';

    html {
        min-width: 1200px;
        overflow-x: auto;
    }

    body::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Edge 隐藏滚动条 */
    }

    /* For Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
        width: 8px; /* Thin scrollbar width */
        height: 8px; /* Thin horizontal scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: #e0e0e0; /* Light grey background for the track */
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Add some inner shadow */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #909090; /* Slightly darker thumb color */
        border-radius: 10px;
        border: 2px solid #e0e0e0; /* Match track background for padding effect */
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); /* Add shadow inside thumb */
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #666; /* Darker when hovered */
        border-color: #d1d1d1; /* Softer padding when hovered */
    }

    /* For Firefox */
    .scroll-container {
        scrollbar-width: thin;
        scrollbar-color: #909090 #e0e0e0; /* Thumb color and track color */
    }

    /* Example content */
    .scroll-container {
        height: 200px;
        overflow-y: scroll;
        border: 1px solid #ccc;
        padding: 10px;
    }

    input[type="number"] {
        -moz-appearance: textfield; /* 去掉 Firefox 中的按钮 */
        -webkit-appearance: none; /* 去掉 Chrome, Safari 中的按钮 */
        margin: 0; /* 去掉可能的外边距 */
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none; /* 去掉上下按钮 */
        margin: 0;
    }

    .scroll-content {
        height: 400px;
    }
</style>
