<template>
    <div class="containerHall">
        <div class="textAlignCenter">
            <img style="width:1144px;" src="/images/home/yxdt_title.png" alt="">
        </div>
        <div class="marketing flex justifyContentSpaceBetween">
            <div @click="routeTo({url: '/webView/live', title: '真人', name: 'live'})"
                 class="item1 transformScale098 relative">
                <img class="absolute" style="width: 119px;right: 6px;top: 13px;"
                     src="/images/home/g2.gif" alt="">
                <p class="font14 color333 absolute" style="top: 120px;left: 75px;">497桌</p>
                <p class="font14 absolute" style="top: 155px;left: 75px;color: #3e5582;">1635人在线</p>
                <div class="font16 colorfff absolute w100" style="top: 205px;left: 50px;height: 38px;">
                    <el-carousel height="38px" direction="vertical" :autoplay="true">
                        <el-carousel-item v-for="(item,index) in carousel" :key="index">
                            <span class="font14">{{item}}</span>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div @click="routeTo({url: '/webView/sport', title: '体育', name: 'sport'})"
                 class="item2 transformScale098 relative">
                <p class="font28 absolute" style="top: 50px;left: 75px;color: #39270c;">体育</p>
                <p class="font14 absolute" style="top: 110px;left: 25px;color: #39270c;">
                    业界最高盘口
                </p>
                <p class="font14 absolute" style="top: 135px;left: 25px;color: #39270c;">
                    赛事高清直播
                </p>
            </div>
            <div @click="routeTo({url: '/webView/fish', title: '捕鱼', name: 'fish'})"
                 class="item3 transformScale098 relative">
                <p class="font28 absolute" style="top: 50px;left: 75px;color: #39270c;">捕鱼</p>
                <p class="font14 absolute" style="top: 110px;left: 25px;color: #39270c;">
                    群雄逐鱼
                </p>
                <p class="font14 absolute" style="top: 135px;left: 25px;color: #39270c;">
                    赚到你手软
                </p>
            </div>
        </div>
        <div class="flex justifyContentSpaceBetween" style="margin-top:50px;">
            <img @click="routeTo(item)" v-for="(item,index) in navBar" :key="index"
                 :src="`/images/home/${item.icon}_i.png`"
                 class="transformScale098"
                 style="width: 267px;" alt="">
        </div>
        <div class="textAlignCenter" style="margin-top: 65px;">
            <img style="width:1144px;" src="/images/home/jzty_title.png" alt="">
        </div>
        <div class="introduce flex justifyContentSpaceBetween" style="margin-top: 90px">
            <div class="flex1 left">
                <div class="font20" style="color:#3e5075;line-height: 32px;">
                    <p class="font36">天子俱乐部APP</p>
                    <p style="margin-top: 40px;">综合手机客户端，便捷的登录方式，丰富的游戏品</p>
                    <p>类，应有尽有的游戏玩法，24小时多平台无缝应用</p>
                    <p>体验，带您开启掌上精彩！</p>
                </div>
                <div class="appDownLoad borderBox relative textAlignCenter">
                    <p class="font14" style="color: #6c4f62;">手机扫描下载</p>
                    <img :src="appDownLoadImg" class="absolute" alt="">
                    <p class="font12 absolute w100">
                        <span>手机快速访问</span>
                        <!--                        <span style="color: #d8b072;">v8.cc</span>-->
                    </p>
                </div>
            </div>
            <div class="right">
                <img style="width: 636px;" src="/images/home/h_sj.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import {qrcanvas} from "qrcanvas"

    import {mapState} from "vuex"

    export default {
        name: "hall",
        computed: {...mapState(['platformInfo', 'navBarInfo'])},
        data() {
            const navBar = [
                {icon: 'dianjing', url: '/webView/esport', name: 'esport'},
                {icon: 'qipai', url: '/webView/boardcard', name: 'boardcard'},
                {icon: 'caipiao', url: '/webView/lottery', name: 'lottery'},
                {icon: 'dianzi', url: '/gameList', name: 'egame'}
            ]
            return {
                navBar,
                appDownLoadImg: '',  //二维码
                carousel: [],    //轮播信息
            }
        },
        watch: {
            'platformInfo': {
                handler(newVal, oldVal) {
                    if (newVal?.appDownloadUrl) {
                        this.appDownLoadImg = qrcanvas({
                            data: newVal?.appDownloadUrl,
                        }).toDataURL('image/png')
                    }
                }, immediate: true
            }
        },
        created() {
            this.generateMessage()
        },
        methods: {
            generateMessage(user) {
                let _fn = () => {
                    return `恭喜用户***${parseInt(Math.random() * 1000)} 赢得 ￥${(Math.random() * 2000).toFixed(2)}`
                }
                let _carousel = []
                for (let i = 0; i < 100; i++) {
                    _carousel.push(_fn())
                }
                this.carousel = [..._carousel]
            },
            routeTo(item) {
                //如果路径相同 则返回
                if (this.$route.path === item.url) return;

                //回到首页
                if (item.url === '/') {
                    this.$router.push({path: item.url})
                    return
                }

                if (!item.name) return

                if (!this.isLogin(true)) return //检测是否登录
                let _currentClick = this.navBarInfo.filter(l => l.name === item.name)

                //未找到对应数据则返回
                if (_currentClick.length === 0) return;
                const _lobby = _currentClick[0]['lobby']
                //lobby是不是 0 ，如果是0 ,就直接进入游戏
                //如果lobby是1 就 进入游戏列表

                let _fn = () => {
                    this.$router.push({path: item.url})
                }

                if (_lobby === 0) {
                    this.loginGame(_currentClick[0], _fn)
                    return
                }

                if (_lobby === 1) {
                    _fn()
                    return
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .containerHall {
        .marketing {
            margin-top: 80px;

            .item1 {
                width: 520px;
                height: 270px;
                background: url("@images/home/zhenren_i.png") no-repeat center center/cover;
            }

            .item2, .item3 {
                width: 280px;
                height: 270px;
                background: url("@images/home/tiyu_i.png") no-repeat center center/cover;
            }

            .item3 {
                background: url("@images/home/buyu_i.png") no-repeat center center/cover;
            }
        }

        .introduce {
            .appDownLoad {
                width: 155px;
                height: 210px;
                margin-top: 75px;
                padding-top: 15px;
                background: url("@images/home/h_ewm_bg.png") no-repeat center center/cover;

                > img {
                    width: 115px;
                    height: 115px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                p:last-child {
                    bottom: 12px;
                }
            }
        }

        ::v-deep {
            .el-carousel__indicators--vertical {
                display: none;
            }
        }
    }
</style>
