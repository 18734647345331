<template>
    <div class="container">
        <div class="content borderBox h100 marginAuto flex justifyContentSpaceBetween">
            <div class="flex alignItemsCenter">
                <div class="logo">
                    <img style="width: 191px" src="/images/header/logo.png" alt="logo">
                </div>
                <div class="h100 navBar">
                    <div class="h100 flex alignItemsCenter">
                        <div @click.stop="routeTo(item)" v-for="(item,index) in navBar" :key="index"
                             :class="{currentNav:item.url && $route.path === item.url}"
                             class="navItme relative h100 font16 pointer">
                            {{item.title}}
                            <template v-if="item.title === '更多游戏'">
                                <div class="absolute w100"
                                     style="height: 4px;bottom: 0;transform: translate(0,100%)"></div>
                                <div class="moreNavBar slidedown absolute">
                                    <p @click.stop="routeTo(l)" v-for="(l,i) in moreNavBar" :key="i"
                                       :class="{currentNav:l.url && $route.path === l.url}"
                                       class="pointer flex alignItemsCenter justifyContentCenter">
                                        <img :src="`/images/header/more_${l.icon}.png`" alt="">
                                        <span>{{l.title}}</span>
                                    </p>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justifyContentCenter">
                <div class="h100 tools flex alignItemsCenter">
                    <div v-for="(item,index) in tools" :key="index"
                         class="relative pointer flex column alignItemsCenter justifyContentCenter">
                        <img :src="`/images/header/h_${item.icon}.png`" :alt="item.title" class="iconImg">
                        <span class="font12">{{item.title}}</span>
                        <template v-if="item.title === 'App'">
                            <div class="appDownLoad slidedown textAlignCenter borderBox absolute">
                                <p class="font14">手机扫描下载</p>
                                <img :src="appDownLoadImg" class="absolute" alt="">
                                <p class="font12 absolute w100" style="bottom: 15px">
                                    <span>手机快速访问 </span>
                                    <!--                                    <span>v8.cc</span>-->
                                </p>
                            </div>
                        </template>
                    </div>
                    <div v-if="isLogin(false)"
                         @click="$router.push({path: '/userCenter/financialDetails'}).catch(error=>'')"
                         class="relative pointer flex column alignItemsCenter justifyContentCenter">
                        <img src="/images/header/h_mingxi.png" alt="资金明细" class="iconImg">
                        <span class="font12">资金明细</span>
                    </div>
                </div>
                <template v-if="!isLogin(false)">
                    <div class="notLogin font16 textAlignCenter flex alignItemsCenter">
                        <p @click="setStoreState({prop:'showLogin',value:'login'})"
                           class="login pointer hover colorfff">登录</p>
                        <p @click="setStoreState({prop:'showLogin',value:'register'})" class="register pointer hover">
                            注册</p>
                    </div>
                </template>
                <template v-else>
                    <alreadyLogin></alreadyLogin>
                </template>
            </div>
        </div>
        <logOn></logOn>
        <deposit></deposit>
        <withdrawMoney></withdrawMoney>
        <collectionSettings></collectionSettings>
        <addAccount></addAccount>
    </div>
</template>

<script>
    import {qrcanvas} from "qrcanvas"

    import {mapState} from "vuex"

    import {getHomeListApi} from "@/api"

    export default {
        name: "index",
        components: {
            alreadyLogin: () => import('./components/alreadyLogin'),
            logOn: () => import('../logOn'),
            deposit: () => import('../deposit'),
            withdrawMoney: () => import('../withdrawMoney'),
            collectionSettings: () => import('../collectionSettings'),
            addAccount: () => import('../addAccount'),
        },
        computed: {...mapState(['platformInfo'])},
        data() {
            const navBar = [
                {url: '/', title: '首页'},
                {url: '/webView/live', title: '真人', name: 'live'},
                {url: '/webView/sport', title: '体育', name: 'sport'},
                {url: '/webView/fish', title: '捕鱼', name: 'fish'},
                {title: '更多游戏'}
            ]
            const moreNavBar = [
                {url: '/webView/esport', title: '电竞', icon: 'dj', name: 'esport'},
                {url: '/webView/boardcard', title: '棋牌', icon: 'qp', name: 'boardcard'},
                {url: '/webView/lottery', title: '彩票', icon: 'cp', name: 'lottery'},
                {url: '/gameList', title: '电子', icon: 'dz', name: 'egame'}
            ]
            const tools = [
                {title: '赞助', icon: 'zanzhu'},
                {title: 'App', icon: 'app'},
                {title: '客服', icon: 'kefu'},
                // {title: '资金明细', icon: 'mingxi'},
            ]
            return {
                navBar,
                moreNavBar,
                navBarInfo: [], //导航信息
                tools,
                appDownLoadImg: '',  //二维码
            }
        },
        watch: {
            'platformInfo': {
                handler(newVal, oldVal) {
                    if (newVal?.appDownloadUrl) {
                        this.appDownLoadImg = qrcanvas({
                            data: newVal?.appDownloadUrl,
                        }).toDataURL('image/png')
                    }
                }, immediate: true
            }
        },
        created() {
            this.useInterceptedOnMounted(this.init)
        },
        methods: {
            init() {
                getHomeListApi({}).then(res => {
                    this.navBarInfo = res.data.result

                    //缓存到vuex里面

                    this.setStoreState({prop: 'navBarInfo', value: res.data.result})

                })
            },
            routeTo(item) {
                //如果路径相同 则返回
                if (this.$route.path === item.url) return;

                //回到首页
                if (item.url === '/') {
                    this.$router.push({path: item.url})
                    return
                }

                if (!item.name) return

                if (!this.isLogin(true)) return //检测是否登录

                let _currentClick = this.navBarInfo.filter(l => l.name === item.name)

                //未找到对应数据则返回
                if (_currentClick.length === 0) return;
                const _lobby = _currentClick[0]['lobby']
                //lobby是不是 0 ，如果是0 ,就直接进入游戏
                //如果lobby是1 就 进入游戏列表

                let _fn = () => {
                    this.$router.push({path: item.url})
                }

                if (_lobby === 0) {
                    this.loginGame(_currentClick[0], _fn)
                    return
                }

                if (_lobby === 1) {
                    _fn()
                    return
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        height: 80px;
        background-image: linear-gradient(0deg,
                #fffdfb 0%,
                #f9f5f1 54%,
                #fffdfa 76%,
                #f8f3ed 92%),
        linear-gradient(
                        #f5f8ff,
                        #f5f8ff);
        background-blend-mode: normal,
        normal;
        box-shadow: 0px 2px 3px 0px rgba(0, 93, 141, 0.13);

        .content {
            padding: 0 20px;
            width: 1200px;

            .navBar {
                line-height: 80px;
                margin-left: 40px;
                color: #39270c;

                .navItme {
                    line-height: 80px;

                    &.current {
                        color: #e5bf5c;
                    }

                    &:not(:last-child) {
                        margin-right: 30px;
                    }

                    &:hover {
                        color: #e5bf5c;

                        &:last-child::after {
                            transform: translate(calc(100% + 5px), -50%) rotateZ(180deg);
                        }

                        .moreNavBar {
                            display: block;
                        }
                    }

                    &.currentNav {
                        color: #e5bf5c;
                    }

                    &:last-child::after {
                        display: block;
                        content: '';
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        top: 50%;
                        right: 0;
                        transition: .3s;
                        transform-origin: center center;
                        transform: translate(calc(100% + 5px), -50%) rotateZ(0deg);
                        background: url("@images/header/h_jt_s.png") no-repeat center center/contain;
                    }
                }

                .moreNavBar {
                    display: none;
                    right: 0;
                    left: 50%;
                    overflow: hidden;
                    transform: translate(-50%, 4px);
                    width: 125px;
                    color: #4c3e2a;
                    background-color: #ffffff;
                    box-shadow: 0px 3px 7px 0px rgba(144, 102, 47, 0.31);
                    border-radius: 10px;

                    p {
                        line-height: 52px;

                        &:hover {
                            background-color: #f2f0ed;
                        }

                        &.currentNav {
                            background-color: #f2f0ed;
                        }

                        img {
                            width: 27px;
                            height: 27px;
                            margin-right: 20px;
                        }
                    }
                }
            }

            .tools {
                color: #39270c;

                > div {
                    padding: 0px 18px;

                    &:hover {
                        > span, > img {
                            transition: .3s;
                            opacity: .7;
                        }

                        .appDownLoad {
                            display: block;
                        }
                    }
                }

                .iconImg {
                    width: 25px;
                    height: 25px;
                    margin-bottom: 10px;
                }

                .appDownLoad {
                    display: none;
                    color: #6c4f62;
                    width: 155px;
                    height: 230px;
                    padding-top: 35px;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 100%);
                    background: url("@images/header/app_xz.png") no-repeat center center/contain;

                    > img {
                        width: 115px;
                        height: 115px;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, calc(-50% + 10px));
                    }
                }
            }

            .notLogin {
                p {
                    width: 86px;
                    height: 37px;
                    line-height: 30px;
                }

                .login {
                    margin-left: 13px;
                    background: url("@images/header/login_btn.png") no-repeat center center/cover;
                }

                .register {
                    color: #39270c;
                    margin-left: 20px;
                    background: url("@images/header/reg_btn.png") no-repeat center center/cover;
                }
            }
        }
    }
</style>
